.network-container {
    background-color: #fff7dc;
    min-height: 1000px;
    width: 100%;
    padding-top: 0.5%;
    height: 100%;
}

.network-page-middle {
    padding-left: 3%;
}


.mobile-network-filter-container {
    display: none;
}

.network-filter-container {
    display: flex;
    flex-direction: row;
    height: 50px;
    justify-content: flex-start;
    align-items: center;
    width: max-content;
    margin-bottom: 0.5%;
}

.multi-select-container {
    min-width: 40%;
    margin-right: 3%;
}

.friend-request-mobile {
    display: none;
}

.friends-container {
    height: auto;
    width: 100%;
    border-radius: 25px;
    text-align: left;
    background-color: #fbfaf5;
    padding-left: 2%;
    padding-top: 2%;
    padding-bottom: 1%;
    margin-bottom: 4%;
}
  
  .message-friend-button {
    text-align: center;
    height: 30px;
    width: auto;
    background-color: rgb(235, 189, 38);
    color: black;
    font-weight: 600;
    border-radius: 5px;
    border: none;
    min-width: fit-content;
    max-height: 30px;
  }

  .filter-button {
    text-align: center;
    height: 38px;
    width: auto;
    background-color: rgb(235, 189, 38);
    color: black;
    font-weight: 600;
    border-radius: 5px;
    border: none;
    min-width: fit-content;
    margin-left: 3%;
  }

  .recommended-friends {
    width: 85%;
    margin: 0% 0% 3% 0%;
    background-color: #fbfaf5;
    text-align: left;
    border-radius: 25px;
    padding: 2% 1% 1% 3%;
    height: fit-content;
}


.network-friends-row {
    display: flex;
    height: fit-content;
    align-items: center;
    align-self: center;
    padding-left: 5%;
    width: 70%;
}

.network-friends-image {
    width: 10%;
    margin-right: 8%;
    border-radius: 50%;
}

.network-friends-info {
    width: auto;
}

.network-friends-name {
    margin-bottom: 0%;
    font-weight: 600;
}

.search-user-container {
    margin-bottom: 7%;
    display: flex;
}

.friend-interact-buttons {
    display: flex;
    justify-content: flex-end;
    margin-right: 5%;
    align-items: center;
    width: 30%;
}

.friend-interact-buttons button {
    margin-left: 10%;
}

.search-options {
    width: fit-content;
    outline: none;
    border: #fbfaf5;
    background: #fbfaf5;
    margin-right: 3%;
    padding-left: 1%;
    border-radius: 4px;
    height: 38px;
}

.mobile-filters {
    display: inline;
}


.rmsc {
    --rmsc-main: none !important;
    --rmsc-hover: #f1f3f5;
    --rmsc-selected: --rmsc-gray !important;
    --rmsc-border: none !important;
    --rmsc-gray: #fbfaf5;
    --rmsc-bg: #fbfaf5 !important;
    --rmsc-p: 10px; /* Spacing */
    --rmsc-radius: 4px; /* Radius */
    --rmsc-h: 38px; /* Height */
}


@media only screen and (max-width: 572px) {

    .network-container {
        margin-top: 10%;
        padding-top: 3%;
    }

    .network-page-middle {
        padding-left: 0%;
    }

    .friends-container {
        width: 96%;
        margin-left: 2%;
    }

    .mobile-network-filter-container {
        display: flex;
        flex-direction: column;
        height: 50px;
        justify-content: flex-start;
        align-items: center;
        margin-top: 0.5%;
        width: 98%;
        margin-left: 1%;
        margin-right: 1%;
        margin-bottom: 0.5%;    
        height: 100px;
        padding-left: 0%;
    }

    .friend-interact-buttons {
        margin-right: 3%;

    }

    .network-filter-container {
        display: none;
    }
    
    .multi-select {
        margin-right: 2%;
    }


    .message-friend-button {
        padding: 0 2px 0 2px;
        text-align: center;
    }

    .friend-requests-mobile {
        display: block;
    }

    .mobile-filters {
        display: flex;
        margin-bottom: 4%;
        align-self: flex-start;
    }

}