@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap");

.signup-container {
    background-color: #fff7dc;
    min-height: 100vh;
    height: auto;
    padding-bottom: 2%;
}

.signup-button {
    background-color: rgb(235, 189, 38);
    color: white;
    padding: 0px 20px 0px 20px;
    width: auto;
}

h1 {
    font-family: "Inter", sans-serif;
    font-weight: 700;
}
  
.signup-text h1 {
    width: 60%;
    min-width: 400px;
}

.signup-text-gold {
    width: 60%;
    min-width: 400px;
    color: rgb(235, 189, 38);
}

.new-account-text h1 {
    color: "black";
    font-family: "Inter", sans-serif;
    font-weight: 700;
}

.form-label-text {
    font-family: "Inter", sans-serif;
    font-weight: 700;
    padding-top: 2.5%;
}
