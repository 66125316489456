.pods-page-container {
  background-color: #fff7dc;
  height: auto;
  min-height: 100vh;
  width: 100%;
  padding-top: 0.5%;
  
}

.connect-button {
  background-color: rgb(235, 189, 38);
  color: black;  
  padding: 0px 20px 0px 20px;
  width: auto;
  border-radius: 20px;
  border: none;
  height: 20px;
  width: auto;
  letter-spacing: 1px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.9rem;
}

.pod-members-container{
  height: auto;
  width: 100%;
  border-radius: 25px;
  text-align: left;
  padding-top: 2%;
  background-color: #fbfaf5;
  margin-bottom: 5%;
}

.pod-members-container h5 {
  color: black;
  padding-left: 20px;
}

.about-pod-section {
  height: auto;
  width: 100%;
  border-radius: 25px;
  text-align: left;
  padding-top: 2%;
  background-color: #fbfaf5;
  margin-bottom: 5%;
}

.about-pod-section h4 {
  padding-left: 1%;
  margin-bottom: 2%;
}
.about-pod-section p {
  padding-left: 1%;
}

.pods-header {
  height: 350px;
  width: 100%;
  border-radius: 25px;
  margin-bottom: 5%;
  display: flex;
  flex-direction: column;
  background-color: #fbfaf5;
}

.pods-top {
  margin-left: -1.4%;
  height: 45%;
  width: 102.85%;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
}

.pods-bg-image {
  width: 100%;
  height: 100%;
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
}

.pods-bottom {
  display: flex;
  flex-direction: row;
}

.pods-description {
  height: 55%;
  width: auto;
  margin-left: 30%;
  text-align: left;
  padding-top: 1%;
  
}

.pods-avatar {
  background-color: lemonchiffon;
  margin-left: 0 auto;
  border-radius: 50%;
  width: 100%;
  height: auto;
  border: 3px solid white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}

.pods-avatar-holder {
  position: absolute;
  z-index: 1;
  height: 55%;
  width: 13%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2%;
}

.edit-pods-button {
  text-align: center;
  height: 20%;
  display: flex;
  background-color: rgb(235, 189, 38);
  color: black;
  padding: 0px 0px 0px 10px;
  border-radius: 5px;
  border: none;
  height: 20px;
  width: 130px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.9rem;
}

.edit-image {
    height: 100%;
    margin-right: 14px;
    padding-bottom: 3px;
    padding-top: 3px;
}

.pods-links {
    justify-content: space-between;
  }
  
.pods-links a{
  padding-right: 6%;
}

.pod-member {
  display: flex;
  align-items: center;
  margin-bottom: 1.5%;
}

.pod-member-info {
  display: flex;
  flex-direction: column;
  padding-left: 2%;
  width: 80%;
}

.pod-member-image {
  margin-left: 1%;
  height: 100%;
  max-height: 35px;
}

.similar-startup-image-container {
  width: 20%;
  display: flex;
  justify-content: center;
}

.similar-startup-image {
  height: 100%;
  max-height: 40px;
  max-width: 100%;
}

.similar-startups {
  display: flex;
  align-items: center;
  margin-bottom: 5%;
  padding-left: 2%;  
}

.pods-list-container {
  height: auto;
  text-align: left;
  padding-top: 2%;
  background-color: #fbfaf5;
  padding-bottom: 3%;
}

.select-pod-profile {
  border-style: none;
  width: 100%;
  background-color: #fbfaf5;
}

.pod-display-summary {
  height: 10%;
  display: flex;
}

.pod-list-avatar {
  border-radius: 50%;
  width: 15%;
  align-self: center;
  margin-left: 3%;
}

.pod-list-text-display {
  padding-left: 4%;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-top: 4%;
}

.pod-list-avatar-name {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 0%;
}

.pod-description {
  font-size: 10px;
  overflow: hidden;
  white-space: nowrap;
  max-width: 28ch;
  text-overflow: ellipsis;
}

.create-pod-button {
  border-radius: 10px;
  border: none;
  height: 40px;
  width: 20%;
  background-color: rgb(235, 189, 38);
  font-weight: 600;
  color: black;
  padding: 0px 20px 0px 20px;
  width: auto;
}

.pod-posts-container {
  height: auto;
  width: 100%;
  border-radius: 25px;
  text-align: left;
  padding-top: 2%;
  padding-bottom: 5%;
  background-color: #fbfaf5;
  margin-bottom: 5%; 
}

.roles-wanted-input {
  width: 100%;
  background-color: rgb(255, 253, 243);
  border-radius: 5px;
  border-style: none;
  margin-bottom: 3%;
}

.pods-posts-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-pod-container {
  border: 1px solid rgb(226, 226, 226);
  height: auto;
  width: 100%;
  border-radius: 25px;
  text-align: left;
  background-color: #fbfaf5;
  padding-top: 2%;
  padding-left: 2%;
  margin-bottom: 5%;
  padding-bottom: 3%;
}

.add-pod-container h4 {
  padding-left: 2%;
  margin-bottom: 4%;
}


.add-pod-post-button {
  border-radius: 10px;
  border: none;
  height: 30px;
  width: 20%;
  background-color: rgb(235, 189, 38);
  font-weight: 600;
  color: black;
  padding: 0px 10px 0px 10px;
  width: auto;
}

@media only screen and (max-width: 572px) {
  .pods-page-container {
    margin-top: 10%;
  }
}