.pods-page-container {
    background-color: #fff7dc;
    height: auto;
    padding-top: 0.5%;
}

.connect-button {
    background-color: rgb(235, 189, 38);
    color: black;  
    padding: 0px 20px 0px 20px;
    width: auto;
    border-radius: 20px;
    border: none;
    height: 20px;
    width: auto;
    letter-spacing: 1px;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 0.9rem;
}

.members h5 {
    color: black;
    padding-left: 20px;
}

.about-pod-section {
    height: auto;
    width: 100%;
    border-radius: 25px;
    text-align: left;
    padding-top: 2%;
    background-color: #fbfaf5;
    margin-bottom: 5%;
  }
  
  .about-pod-section h4 {
    padding-left: 1%;
    margin-bottom: 2%;
  }
  .about-pod-section p {
    padding-left: 1%;
  }

  .members-pod-section {
    height: auto;
    width: 100%;
    border-radius: 25px;
    text-align: left;
    padding-top: 2%;
    background-color: #fbfaf5;
    margin-bottom: 5%;
  }
  
  .members-pod-section h4 {
    padding-left: 1%;
    margin-bottom: 2%;
  }
  .members-pod-section p {
    padding-left: 1%;
  }