@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Baloo+2:wght@800&family=Inter:wght@300;500&display=swap");

.navbar a {
  color: black !important;
  text-decoration: none !important;
}

:root {
  font-size: 15px;
  --bg-color: #fff7dc;
}

.navbar {
  background: var(--bg-color);
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  height: 10vh;
  width: 100%;
  background-image: url("https://www.transparenttextures.com/patterns/az-subtle.png");
}

.logo {
  height: 50px;
  padding-right: 0px;
  padding-bottom: 0px;
  image-rendering: -webkit-optimize-contrast;
}

.logo-section,
.sections,
.navbar-buttons {
  width: 33%;
}

.logo-section {
  font-family: "Baloo 2", cursive;
  font-size: 1.8rem;
  color: black;
  display: flex;
  align-content: center;
  justify-content: center;
}

.logo-section p {
  margin-bottom: 0%;
  align-self: center;
}

.sections,
.navbar-buttons {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  letter-spacing: 1px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
}

.navbar-buttons {
  align-items: center;
  justify-content: center;
}

.navbar-section {
  height: 100%;
  width: auto;
  padding: 0px 20px 0px 20px;
  color: black;
}

.signup-button {
  background-color: rgb(39, 39, 51);
  /* margin-left: 20px; */
  color: white;
}

.navbar-button {
  border-radius: 10px;
  border: none;
  height: 40px;
  width: auto;
  letter-spacing: 1px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.9rem;
}

.navbar-button a {
  color: white;
}

@media only screen and (max-width: 572px) {


  .navbar {
    justify-content: space-around;
  }

  .navbar-section {
    padding: 0;
    font-size: 10px;
  }

  .mobile-about-tab {
    padding-right: 20px;
  }
  
  .mobile-profile-tab {
    padding-right: 10px;
  }

  .section {
    width: 50%;
  }

  .navbar-buttons {
    width: 40%;
  }

  .navbar-button {
    text-align: center;
  }

  .navbar-button p {
    margin-bottom: 0%;
  }

  .logo-section {
    width: 10%;
  }

  .logo-section p {
    display: none;
  }
}