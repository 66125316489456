.feed-page-container {
    background-color: #fff7dc;
    height: auto;
    min-height: 100vh;
    width: 100%;
    display: flex;
}
.feed-page-container-left {
    width: 6%;
}

.feed-page-container-middle {
    width: 65%;
    margin-top: 0.5%;
}

.feed-page-container-right {
    width: 29%;
    margin: 1% -2% 1% 5%;
}

.feed-container {
    width: 100%;
    margin-left: 5%;
    background-color: #fbfaf5;
    border-radius: 25px;
    padding-top: 1%;
    padding-bottom: 3%;
    margin-bottom: 3%;
}


.search-bar-container {
    width: 100%;
    margin-left: 5%;
    display: flex;
    border-radius: 10px;  
    background-color: #fff7dc;
    max-height: 50px;
}

.avatar-search-bar-holder {
    width: 15%;
    align-self: center;
    margin-right: 2%;
    max-width: 30px;
    margin-left: 3%;
    border-style: none;
    background-color: #fbfaf5;
}

.avatar-search-bar {
    width: 175%;
}

.search-bar {
    width: 85%;
    padding-top: 1%;
    padding-bottom: 1%;
    background-color: #fbfaf5;
    border-style: hidden;
}

.search-bar:focus {
    outline: none;
}

.search-icon-container {
    align-self: center;
    max-height: 50px;
    height: 100%;
    width: 4%;
    margin-left: 5%;
    margin-right: 2%;
    background-color: rgb(235, 189, 38);
    border-radius: 10%;
    border-style: none;
}

.search-icon {
    height: 100%;
    width: 100%;
}

.post-types {
    display: flex;
    width: 100%;
    margin-left: 5%;
    margin-top: 3%;
    margin-bottom: 3%;
}

.post-types-left {
    width: 75%;
    max-width: 75%;
    display: flex;
    align-items: left;
    padding-left: 3%;
}

.post-types-button {
    border-radius: 10px;
    border: none;
    height: 40px;
    width: 100%;
    background-color: #fbfaf5;
    color: black;
    padding: 0px 20px 0px 20px;
    width: auto;
    margin-right: 3%;
    opacity: 0.5;
}

.add-post-button {
    border-radius: 10px;
    border: none;
    height: 40px;
    width: 20%;
    background-color: rgb(235, 189, 38);
    font-weight: 600;
    color: black;
    padding: 0px 20px 0px 20px;
    width: auto;
}

.post-types-button:active, .post-types-button:hover, .post-types-button:focus {
    opacity: 1.0;
}

.feed-post-user {
    display: flex;
    text-align: left;
    max-height: 75px;
}

.feed-post-user-info {
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 80%;
}

.feed-post-user-info p {
    margin-bottom: 0%;
}
 
.avatar-feed-button {
    border-style: none;
    background-color: transparent;
}

.avatar-feed-post {
    height: 75%;
    border-radius: 50%;
}

.startup-name-feed-post {
    display: flex;
    padding-left: 4%;
    margin-bottom: 1%;
}


.startup-name-feed-post h5 {
    margin-right: 3%;
}

.startup-logo {
    height: auto;
    max-height: 25px;
    width: auto;
    border-radius: 15px;
}

.feed-post-content {
    text-align: left;
    padding-left: 5%;
}

.roles-needed {
    text-align: left;
    padding-left: 4%;
    display: flex;
}

.interest-buttons-container {
    display: flex;
    width: 100%;
    padding-left: 5%;
    justify-content: space-between;
}

.save-button {
    border-radius: 10px;
    border-style: none;
    max-height: 30px;
    min-width: fit-content;
    align-self: center;
    font-weight: 600;
    background-color: #fbfaf5;
    color: black;
    margin-right: 3%;
}

.interest-buttons {
    border-radius: 10px;
    border-style: none;
    max-height: 30px;
    min-width: fit-content;
    font-weight: 600;
    background-color: rgb(235, 189, 38);
    color: black;
    margin-right: 3%;
    padding: 1% 1% 4% 1%;
}

.recommended-friends {
    width: 85%;
    margin: 0% 0% 3% 0%;
    background-color: #fbfaf5;
    text-align: left;
    border-radius: 25px;
    padding: 2% 1% 1% 3%;
    height: fit-content;
}


.recommended-friends-row {
    display: flex;
    height: fit-content;
    align-items: center;
    align-self: center;
    padding-left: 5%;
}

.recommended-friends-image {
    width: 25%;
    margin-right: 8%;
    border-radius: 50%;
}

.recommended-friends-info {
    width: auto;
}

.recommended-friends-name {
    margin-bottom: 0%;
    font-weight: 600;
}


.connect-friend-button {
    background-color: rgb(235, 189, 38);
    color: black;
    width: auto;
    border-radius: 5px;
    border: none;
    height: 20px;
    width: auto;
    letter-spacing: 1px;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 0.9rem;
}

.create-post-input {
    width: 100%;
    background-color: rgb(255, 253, 243);
    border-radius: 5px;
    border-style: none;
    margin-bottom: 3%;
}
  
textarea:focus, input:focus {
    outline: none;
}

.select-pod {
    background-color: #ffffff;
    color: black;
    width: auto;
    padding: 5px;
    border-radius: 5px;
    border: solid;
    border-width: 1px;
    height: auto;
    width: auto;
    font-weight: 400;
    margin-bottom: 5%;
}

.optional-label {
    margin-bottom: 0.5%;
    font-size: 12px;
    padding-left: 2%;
}

.comments {
    border-top-style: solid;
    border-width: 1px;
    border-color: rgb(235, 189, 38);
    margin-top: 1%;
    padding-top: 1%;
    text-align: left;
    padding-left: 8%;
    width: 100%;
}

.comment-input {
    margin-bottom: 2%;
}

.comment-row {
    display: flex;
    margin-left: 2%;
    max-height: 50px;
    margin-bottom: 3%;
}

.comments-user-image {
    margin-top: 1%;
    border-radius: 50%;
    width: 4%;
    height: 4%;
}

.comment-right {
    display: flex;
    flex-direction: column;
    margin-left: 3%;
    width: 80%;
    margin-top: 0%;
    padding-top: 0%;
}

.comment-user {
    margin-bottom: 1%;
    font-weight: 500;
}

.comment-message {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 0%;
    color: slategrey;
}


@media only screen and (max-width: 572px) {
    .feed-page-container {
        padding-bottom: 0;
        margin-bottom: 0;
        margin-top: 10%;
    }

    .feed-page-container-left {
        display: none;
    }
    .feed-page-container-right {
        display: none;
    }
    .feed-page-container-middle {
        width: 100%;
        padding: 2% 5% 70px 5%;
    }

    .feed-container {
        width: 100%;
        margin-left: 0%;
        padding-left: 0%;
        background-color: #fbfaf5;
        border-radius: 25px;
    }
    .search-bar-container {
        width: 100%;
        margin-left: 0%;
        display: flex;
        border-radius: 10px;  
        background-color: #fff7dc;
        max-height: 50px;
        justify-content: flex-start;
    }

    .post-types {
        display: flex;
        width: 100%;
        margin-left: 0%;
        margin-top: 3%;
        margin-bottom: 3%;
    }

    .post-types-button {
        font-size: 12px;
        padding: 0px 5px 0px 5px;
        height: 30px;
    }

    .add-post-button {
        height: 30px;
        font-size: 12px;
        padding: 0px 5px 0px 5px;
    }

    .avatar-search-bar {
        width: 100%;
    }

    .search-icon-container {
        width: 7%;
    }

    .avatar-feed-button {
        width: 35%;
    }

    .comment-row {
        max-height: fit-content;
    }

    .comment-right p {
        margin-bottom: 0%;
    }

    .feed-post-user {
        height: 75px;
    }

    .avatar-feed-post {
        width: 50px;
        height: 50px;
    }

    .recommended-friends {
        width: 96%;
        margin: 2% 0% 3% 2%;
        border-radius: 25px;
        padding: 2% 1% 1% 3%;
    }

    .comments-user-image {
        width: 25px;
        height: 25px;
    }
}