.contactus-page-container {
  height: 100%;
  background-color: #fff7dc;
  background-image: url("https://www.transparenttextures.com/patterns/az-subtle.png");
}

.page-landing {
  padding-top: 1%;
  width: 80%;
  height: 100%;
  font-family: "Inter", sans-serif;
  display: flex;
  margin-left: 10%;
  margin-right: 10%;
  justify-content: space-evenly;
}

.landing-left {
  text-align: left;
  font-family: "Inter", sans-serif;
  margin-bottom: 5%;
}

.landing-right-contact {
  margin-top: 7%;
  margin-bottom: 10%;
}

.page-heading h2 {
  margin-left: 10%;
  padding-bottom: 1%;
}

.contactus-image {
  width: 100%;
}

.input-form {
  background-color: rgb(37, 36, 55);
  height: auto;
  width: 80%;
  border-radius: 20px;
  padding: 5%;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.input-form h4 {
  width: 100%;
  text-align: left;
}

.input-form input {
  height: 40px;
}

.input-field {
  width: 100%;
  background-color: rgb(255, 253, 243);
  border-radius: 5px;
  border-style: none;
  padding-left: 4%;
}

.contactus-button {
  border-radius: 10px;
  border: none;
  height: 40px;
  width: 20%;
  letter-spacing: 1px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.9rem;
  background-color: rgb(235, 189, 38);
  color: white;
  padding: 0px 20px 0px 20px;
  width: auto;
  margin-top: 5%;
}

@media only screen and (max-width: 572px) {
  .landing-right-contact {
    display: none;
  }

  .landing-left {
    width: 100%;
  }
  
  .page-landing {
    width: 90%;
    min-height: 100vh;
    display: flex;
    margin-left: 5%;
    margin-right: 5%;
    justify-content: center;
  }

  .input-form {
    width: 100%;
  }
}
