.about-container {
  height: auto;
  background-color: #fff7dc;
  background-image: url("https://www.transparenttextures.com/patterns/az-subtle.png");
}

.landing {
  padding: 4% 0 4% 0;
  width: 80%;
  height: 100%;
  font-family: "Inter", sans-serif;
  display: flex;
  margin: 0 10% -40px 10%;
  justify-content: space-evenly;
}

.landing-left {
  margin-top: 4%;
  /* background-color: #fff7dc; */
  width: 100%;
  text-align: left;
  margin-bottom: 10%;
}

.landing-right {
  text-align: center;
}

.landing-right-image {
  width: 100%;
}

.landing-text {
  text-align: left;
  width: 90%;
  margin-top: 5%;
}

.landing-header {
  width: 40%;
  display: inline;
}

.landing-header h1 {
  font-weight: 700;
}

.line-after h1:after {
  background-color: rgb(235, 189, 38);
  content: "";
  padding-left: 2px;
  display: inline-block;
  height: 4px;
  font-weight: 500;
  position: relative;
  vertical-align: middle;
  width: 40%;
}

.landing-right {
  width: 100%;
}

.signup-free-button {
  margin-left: 2%;
  border-radius: 10px;
  border: none;
  height: 40px;
  width: 20%;
  letter-spacing: 1px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.9rem;
  background-color: rgb(37, 36, 55);
  color: white;
  padding: 0px 20px 0px 20px;
  width: auto;
}

.why-omelette-container {
  margin-top: 4%;
  background-color: rgb(255, 253, 243);
  height: 500px;
}

.why-omelette-container h3 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  text-align: center;
}

.why-omelette {
  padding-top: 2%;
  width: 90%;
  padding-left: 10%;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  height: auto;
}

.why-omelette-columns {
  text-align: center;
  width: 50%;
}

.why-omelette-columns span {
  color: rgb(235, 189, 38);
}

.column-image {
  height: 60%;
  margin-bottom: 30px;
  width: 60%;
}

.blog-page-container {
  height: auto;
  background-color: #fff7dc;
}

.page-heading {
  text-align: left;
  font-family: "Inter", sans-serif;
  padding-top: 2%;
}

.page-heading h2 {
  margin-left: 10%;
  padding-bottom: 1%;
  font-family: "Inter", sans-serif;
}

.testimonials-container {
  margin-top: 4%;
  text-align: left;
  margin-left: 5%;
  margin-right: 5%;
}

.faqs {
  width: 80%;
  margin-left: 10%;
}

.faqs h2 {
  text-align: left;
  color: rgb(37, 36, 55);
}

.faqs-row {
  display: flex;
}

.faq-accordian {
  width: 45%;
}

.faq-divider {
  width: 10%;
}

.e-accordion .e-acrdn-item .e-acrdn-header {
  background-color: #fff7dc;
  border-radius: 6px 6px 6px 6px;
}

.faq-question {
  background-color: #fff7dc;
}

.faq-answer {
  background-color: #fefefe;
}

.testimonials {
  border-radius: 25px;
  border: rgb(235, 189, 38);
  border-style: solid;
  height: 200px;
}

.faqs-container {
  height: auto;
  padding-bottom: 2%;
  width: 100%;
  background-image: none !important;
  background-color: #fff7dc !important;
}

.our-story-container {
  background-color: rgb(255, 253, 243);
  padding-bottom: 2%;
  padding-top: 4%;
}

.our-story-container h3 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  text-align: center;
}

.our-story {
  padding-top: 2%;
  width: 100%;
  justify-content: space-around;
  display: flex;
  flex-direction: row;
  height: auto;
}

.our-story-column {
  width: 33%
}

.our-story h5 {
  text-align: center;
  padding-top: 1%;
}

.our-story p {
  text-align: left;
  width: 90%;
  padding-left: 5%;
}

.our-story-image {
  width: 200px;
  border-radius: 50%;
}

@media only screen and (max-width: 572px) {
  .landing-right {
    display: none;
  }

  .landing {
    margin-bottom: 2%
  }

  .why-omelette-container {
    height: fit-content;
    padding-bottom: 2%;
  }

  .why-omelette {
    display: block;
  }

  .why-omelette-columns {
    width: 100%;
  }

  .our-story {
    display: block;
  }

  .our-story-column {
    width: 100%;
  }

  .faqs-row {
    display: block
  }
  
  .faq-accordian {
    width: 100%;
  }

  .faq-divider {
    display: none;
  }

}
