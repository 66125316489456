@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap");

.login-container {
    background-color: #fff7dc;
    min-height: 100vh;
}

.login-button {
    background-color: rgb(235, 189, 38);
    color: white;
    padding: 0px 20px 0px 20px;
    min-width: fit-content;
    border-radius: 10px;
    border: none;
    height: 50px;
    letter-spacing: 1px;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 0.9rem;
    margin-right: 5%;
}

.signin-text {
    font-size: 50px;
    color: black;
    font-family: "Inter", sans-serif;
    font-weight: 600;

}

.connect-text {
    font-size: 20px;
    color: rgb(235, 189, 38);
}

.new-to-text {
    font-size: 30px;
    color: black;
}

h1 {
    font-family: "Inter", sans-serif;
    font-weight: 700;
}

h2 {
    font-family: "Inter", sans-serif;
    font-weight: 700;
}

.google-button {
    border-radius: 25px;
}
  