.side-nav-container {
    width: 6%;
}

.room-nav {
    width: 6%;
    background-color: rgb(235, 189, 38);
    margin-left: 1%;
    border-radius: 25px;
    margin-top: 0.5%;
    height: 98%;
    bottom: 0;
    top: 0;
    z-index: 1; 
    position: fixed;
    min-width: 40px;
}

.nav-icon-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
}

.icon-nav {
    width: 50%;
    height: 100%;
    align-self: center;
    color: black;
}

.nav-link {
    width: 100%;
    border-style: none;
    background-color: rgb(235, 189, 38);
    margin-bottom: 20%;
}

.bottom-tab-nav-container {
    background-color: rgb(235, 189, 38);
    min-height: 60px;
    bottom: 0;
    width: 100%;   
    position: fixed;
    display: flex;
    align-items: center;
}

.bottom-tab-nav {
    width: 100%;
    bottom: 0;
    z-index: 1; 
}

.bottom-tab-icon-group {
    display: flex;
    align-items: center;
    padding-left: 5%;
    padding-right: 5%;
}

.bottom-tab-nav-link {
    width: 100%;
    border-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(235, 189, 38);
}

.bottom-tab-nav-link p {
    margin-bottom: 0;
}

.bottom-tab-icon-nav {
    width: 100%;
    align-self: center;
    color: black;
}

.hover-text {
    color: black;
    margin-bottom: 0;
    display: none;
    font-size: 10px;
}

.nav-link:hover p{
    display: block;
}

.mobile-nav-bar-container {
    background-color: rgb(235, 189, 38);
    width: 100%;
    height: 5%;
    top: 0;
    z-index: 2;
    position: fixed;
    display: flex;
    align-items: center;
}

.mobile-tab-nav {
    width: 30%;
    background-color: rgb(235, 189, 38);
    height: 95%;
    bottom: 0;
    z-index: 2; 
    position: fixed;
    min-width: 40px;
    padding-left: 1%;
    padding-top: 3%;
}

.mobile-tab-icon-group {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.mobile-tab-nav-link {
    width: 100%;
    border-style: none;
    display: flex;
    text-align: left;
    background-color: rgb(235, 189, 38);
    padding-bottom: 25%;
}

.mobile-tab-nav-link p {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 20px;
    width: 60%;
}

.mobile-tab-icon-nav {
    width: 40%;
    align-self: center;
    color: black;
}

.mobile-nav-toggle-button {
    background-color: rgb(235, 189, 38);
    border: none;
}

