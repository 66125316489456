.profile-container {
  background-color: #fff7dc;
  height: auto;
  min-height: 592px;
  padding-top: 0.5%;
  width: 100%;
}

.profile-page-right {
  padding-right: 4%;
  padding-left: 3%;
}

.profile-page-middle {
  padding-left: 3%;
}

.profile-header {
  border: 1px solid rgb(226, 226, 226);
  height: 450px;
  width: 100%;
  border-radius: 25px;
  margin-bottom: 5%;
  display: flex;
  flex-direction: column;
  background-color: #fbfaf5;
}

.profile-top {
  margin-left: -1.4%;
  height: 45%;
  width: 102.85%;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
}

.profile-bg-image {
  width: 100%;
  height: 100%;
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
}

.profile-bottom {
  display: flex;
  flex-direction: row;
}

.profile-description {
  height: 55%;
  width: auto;
  margin-left: 30%;
  text-align: left;
  padding-top: 1%;
}

.profile-description p {
  margin-bottom: 0%;
}

.profile-avatar {
  background-color: lemonchiffon;
  margin-left: 0 auto;
  border-radius: 50%;
  width: 100%;
  border: 6px solid white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}

.profile-avatar-holder {
  position: absolute;
  z-index: 1;
  height: 65%;
  width: 14%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2%;
}

.edit-profile-button-desktop {
  text-align: center;
  height: 20%;
  display: flex;
  background-color: rgb(235, 189, 38);
  color: black;
  padding: 0px 0px 0px 10px;
  border-radius: 5px;
  border: none;
  height: 20px;
  width: 130px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.9rem;
}
.edit-profile-button-mobile {
  display: none;
}

.edit-image {
  height: 100%;
  margin-right: 14px;
  padding-bottom: 3px;
  padding-top: 3px;
}

.number-connections {
  font-size: 16px;
  color:rgb(235, 189, 38);
  font-weight: 500;
  padding-bottom: 2%;
}

.profile-links {
  justify-content: space-between;
  margin-top: 1%;
}

.profile-links a{
  padding-right: 6%;
}

.about-section {
  border: 1px solid rgb(226, 226, 226);
  height: auto;
  width: 100%;
  border-radius: 25px;
  text-align: left;
  padding-top: 2%;
  background-color: #fbfaf5;
  margin-bottom: 5%;
}

.about-section h4 {
  padding-left: 1%;
  margin-bottom: 2%;
}
.about-section p {
  padding-left: 1%;
}

.about-header {
  display: flex;
  margin-bottom: 0.5%;
}

.about-title {
  width: 95%;
}

.about-edit-cancel {
  margin-bottom: 0;
}

.passions-section {
  border: 1px solid rgb(226, 226, 226);
  height: auto;
  width: 100%;
  border-radius: 25px;
  text-align: left;
  padding-left: 2%;
  margin-bottom: 5%;
  padding-top: 2%;
  background-color: #fbfaf5;
}

.passions-section h4 {
  padding-left: 1%;
  margin-bottom: 2%;
}


.myskills-section {
  border: 1px solid rgb(226, 226, 226);
  height: auto;
  width: 100%;
  border-radius: 25px;
  text-align: left;
  background-color: #fbfaf5;
  padding-left: 2%;
  margin-bottom: 5%;
  padding-top: 2%;
}

.myskills-section p {
  padding-left: 10%;
}
.myskills-section h4 {
  padding-left: 2%;
  margin-bottom: 4%;
}

.additional-section {
  border: 1px solid rgb(226, 226, 226);
  height: auto;
  width: 100%;
  border-radius: 25px;
  text-align: left;
  background-color: #fbfaf5;
  padding-top: 2%;
  padding-left: 2%;
  margin-bottom: 5%;
}

.additional-section h4 {
  padding-left: 2%;
  margin-bottom: 4%;
}

.edit-about-me {
  text-align: center;
  background-color: rgb(235, 189, 38);
  color: black;
  border-radius: 5px;
  border: none;
  width: fit-content;
  height: auto;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.9rem;
}

.form-inline .form-control {
  width:auto;
}
.form-inline .form-group {
  display: inline-block;
}

.profile-overview {
  background-color: #fbfaf5;
  border-color: rgb(235, 189, 38);
  border-width: 1px;
  border-style: solid;
  text-align: left;
  border-radius: 20px;
}

.profile-image {
  background-color: #fbfaf5;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  height: 70px;
  padding-left: 30px;
  border-bottom: solid;
  border-bottom-width: 1px;
  border-color: rgb(235, 189, 38);
}

.profile-info {
  background-color: #fbfaf5;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  height: 300px;
  padding-left: 30px;
}

.profile-siding {
  background-color: #fbfaf5;
  text-align: left;
  border-radius: 20px;
}

.edit-profile-link {
  background-color: #fbfaf5;
  border-color: rgb(235, 189, 38);
  border-width: 1px;
  border-style: solid;
  border-radius: 20px;
  height: 70px;
  padding-top: 20px;
  padding-left: 10px;
}

.members p {
  padding-left: 20px;
  white-space: nowrap;
  display: inline;
  color: black;
}

.a {
  text-decoration: none;
}


.message-button {
  text-align: center;
  height: 20%;
  background-color: rgb(235, 189, 38);
  color: black;
  font-weight: 600;
  border-radius: 5px;
  border: none;
  max-height: 30px;
  padding: 1% 10%;
}

.add-skill-button {
  text-align: center;
  height: 20%;
  background-color: rgb(235, 189, 38);
  color: black;
  font-weight: 600;
  border-radius: 5px;
  border: none;
  margin-top: 1%;
  margin-bottom: 3%;
  max-height: 30px;
  padding: 1%;
}

.delete-icon-button {
  background-color: #fbfaf5;
  border-style: none;
  padding-bottom: 10%;
}

.skill-row {
  margin-bottom: 10%;
  max-height: 22px;
  align-content: center;
  display: flex;
}

.skill-text {
  width: fit-content;
  padding-right: 5%;
}

.trash-icon {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}

.portal-bottom-tab-nav {
  display: none;
}

.mobile-profile-pods {
  display: none;
}

.desktop-profile-pods {
  display: none;
}

@media only screen and (max-width: 572px) {
  .side-nav {
    display: none;
  }

  .profile-description {
    margin-left: 25%;
    width: 80%;
  }

  .desktop-profile-pods {
    display: none;
  }

  .mobile-profile-pods {
    display: block;
  }

  .portal-bottom-tab-nav {
    display: block;
  }

  .profile-container {
    margin-right: 0%;
    padding-right: 0%;
    margin-top: 10%;
  }

  .profile {
    background-color: #fff7dc;
    padding: 2% 5% 1% 5%;
    margin-right: 0%;
  }

  .profile-page-right {
    padding-right: 0%;
    padding-left: 0%;
    padding-bottom: 50px;
  }

  .profile-page-middle {
    padding-left: 0%;
  }

  .profile-links {
    display: block;
  }

  .edit-profile-button-desktop {
    display: none;
  }

  .edit-profile-button-mobile {
    text-align: center;
    height: 20%;
    display: flex;
    background-color: rgb(235, 189, 38);
    color: black;
    padding: 0px;
    border-radius: 5px;
    border: none;
    height: 24px;
    width: 24px;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 0.9rem;
  }


}

