@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Baloo+2:wght@800&family=Inter:wght@300;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

a:hover {
  color: black;
  text-decoration: none;
}

a:before {
  color: black;
  text-decoration: none;
}

a {
  text-decoration: none !important;
  color: inherit !important;
}

.about-container {
  height: auto;
  background-color: #fff7dc;
  background-image: url("https://www.transparenttextures.com/patterns/az-subtle.png");
}

.landing {
  padding: 4% 0 4% 0;
  width: 80%;
  height: 100%;
  font-family: "Inter", sans-serif;
  display: -webkit-flex;
  display: flex;
  margin: 0 10% -40px 10%;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

.landing-left {
  margin-top: 4%;
  /* background-color: #fff7dc; */
  width: 100%;
  text-align: left;
  margin-bottom: 10%;
}

.landing-right {
  text-align: center;
}

.landing-right-image {
  width: 100%;
}

.landing-text {
  text-align: left;
  width: 90%;
  margin-top: 5%;
}

.landing-header {
  width: 40%;
  display: inline;
}

.landing-header h1 {
  font-weight: 700;
}

.line-after h1:after {
  background-color: rgb(235, 189, 38);
  content: "";
  padding-left: 2px;
  display: inline-block;
  height: 4px;
  font-weight: 500;
  position: relative;
  vertical-align: middle;
  width: 40%;
}

.landing-right {
  width: 100%;
}

.signup-free-button {
  margin-left: 2%;
  border-radius: 10px;
  border: none;
  height: 40px;
  width: 20%;
  letter-spacing: 1px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.9rem;
  background-color: rgb(37, 36, 55);
  color: white;
  padding: 0px 20px 0px 20px;
  width: auto;
}

.why-omelette-container {
  margin-top: 4%;
  background-color: rgb(255, 253, 243);
  height: 500px;
}

.why-omelette-container h3 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  text-align: center;
}

.why-omelette {
  padding-top: 2%;
  width: 90%;
  padding-left: 10%;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  height: auto;
}

.why-omelette-columns {
  text-align: center;
  width: 50%;
}

.why-omelette-columns span {
  color: rgb(235, 189, 38);
}

.column-image {
  height: 60%;
  margin-bottom: 30px;
  width: 60%;
}

.blog-page-container {
  height: auto;
  background-color: #fff7dc;
}

.page-heading {
  text-align: left;
  font-family: "Inter", sans-serif;
  padding-top: 2%;
}

.page-heading h2 {
  margin-left: 10%;
  padding-bottom: 1%;
  font-family: "Inter", sans-serif;
}

.testimonials-container {
  margin-top: 4%;
  text-align: left;
  margin-left: 5%;
  margin-right: 5%;
}

.faqs {
  width: 80%;
  margin-left: 10%;
}

.faqs h2 {
  text-align: left;
  color: rgb(37, 36, 55);
}

.faqs-row {
  display: -webkit-flex;
  display: flex;
}

.faq-accordian {
  width: 45%;
}

.faq-divider {
  width: 10%;
}

.e-accordion .e-acrdn-item .e-acrdn-header {
  background-color: #fff7dc;
  border-radius: 6px 6px 6px 6px;
}

.faq-question {
  background-color: #fff7dc;
}

.faq-answer {
  background-color: #fefefe;
}

.testimonials {
  border-radius: 25px;
  border: rgb(235, 189, 38);
  border-style: solid;
  height: 200px;
}

.faqs-container {
  height: auto;
  padding-bottom: 2%;
  width: 100%;
  background-image: none !important;
  background-color: #fff7dc !important;
}

.our-story-container {
  background-color: rgb(255, 253, 243);
  padding-bottom: 2%;
  padding-top: 4%;
}

.our-story-container h3 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  text-align: center;
}

.our-story {
  padding-top: 2%;
  width: 100%;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  height: auto;
}

.our-story-column {
  width: 33%
}

.our-story h5 {
  text-align: center;
  padding-top: 1%;
}

.our-story p {
  text-align: left;
  width: 90%;
  padding-left: 5%;
}

.our-story-image {
  width: 200px;
  border-radius: 50%;
}

@media only screen and (max-width: 572px) {
  .landing-right {
    display: none;
  }

  .landing {
    margin-bottom: 2%
  }

  .why-omelette-container {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-bottom: 2%;
  }

  .why-omelette {
    display: block;
  }

  .why-omelette-columns {
    width: 100%;
  }

  .our-story {
    display: block;
  }

  .our-story-column {
    width: 100%;
  }

  .faqs-row {
    display: block
  }
  
  .faq-accordian {
    width: 100%;
  }

  .faq-divider {
    display: none;
  }

}

.home-container {
  height: 500px;
  background-color: var(--bg-color);
  display: -webkit-flex;
  display: flex;
  width: 100%;
  background-image: url("https://www.transparenttextures.com/patterns/az-subtle.png");
}

h1 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
}

.home-text h1 {
  width: 60%;
  min-width: 400px;
}

.home-text p {
  width: 80%;
  min-width: 500px;
}

.home-text {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 10%;
  text-align: left;
  width: 40%;
}

.collaboration {
  height: 70%;
  width: 50%;
}

.home-image {
  width: 60%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.get-started-button {
  background-color: rgb(235, 189, 38);
  color: white;
  padding: 0px 20px 0px 20px;
  width: auto;
}

.home-stat {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  height: auto;
  padding-top: 15px;
}

.home-stat p {
  letter-spacing: 1px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
}

.home-stat h1 {
  font-weight: 900;
  padding: 0 !important;
  margin: 0 !important;
}


.home-user-details {
  background-color: rgb(235, 189, 38);
  height: 140px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-content: center;
          align-content: center;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

.title-info span {
  color: rgb(235, 189, 38);
}

.footer {
  background-color: rgb(37, 36, 55);
  height: 100px;
  bottom: 0;
  width: 100%;
  position: relative;
  background-image: url("https://www.transparenttextures.com/patterns/white-diamond-dark.png");
}

.company-name-footer {
  color: white;
  text-align: left;
  padding-left: 110px;
  padding-top: 5px;
  font-size: 20px;
}

.footer-row {
  display: -webkit-flex;
  display: flex;
}
.terms {
  color: grey;
  text-align: left;
  padding-left: 110px;
  font-size: 14px;
}
.privacy-policy {
  color: grey;
  text-align: left;
  padding-left: 20px;
  font-size: 14px;
}

.vertical-divider {
  height: 100%;
  border: 2px solid rgb(230, 230, 230);
}

.sectional-container {
  width: 90%;
  height: 80%;
  font-family: "Inter", sans-serif;
}

.home-info {
  background-color: rgb(255, 253, 243);
  height: 1200px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  background-image: url("https://www.transparenttextures.com/patterns/connected.png");
}

.title-info {
  letter-spacing: 1px;
  font-size: 30px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  padding-top: 25px;
  height: 10%;
  width: 100%;
}

.sectional-image {
  height: 45%;
  margin-bottom: 30px;
}

.work-together-image {
  margin-left: -60px;
}

.vertical-section {
  width: 30%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

.sectional-container h2,
.sectional-container a,
.sectional-container p {
  width: 100%;
  text-align: left;
}

.sectional-container h2 {
  font-weight: 700;
  font-size: 1.5em;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.sectional-container a {
  font-weight: 700;
}

.home-sectional-top {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: center;
          align-items: center;
  height: 55%;
  width: 100%;
}

.home-sectional-mid {
  height: 7%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

.home-sectional-bottom {
  height: 38%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

.horizontal-section {
  width: 80%;
  height: 90%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.horizontal-text {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  width: 50%;
  height: 50%;
}

.horizontal-text p {
  max-width: 80%;
}

.horizontal-divider {
  width: 40%;
  border: 2px solid rgb(230, 230, 230);
}

.mobile-horizontal-divider {
  display: none;
}

.sectional-button {
  border-radius: 10px;
  border: none;
  height: 40px;
  width: 20%;
  letter-spacing: 1px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.9rem;
  background-color: rgb(37, 36, 55);
  color: white;
  padding: 0px 20px 0px 20px;
  width: auto;
}

.sectional-button a {
  letter-spacing: 1px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.9rem;
}

.rotating-text {
  min-width: 500px;
}
.rotating-text h1 {
  width: 100%;
}

.team-image-mobile {
  display: none;
}


.react-rotating-text-cursor {
  -webkit-animation: blinking-cursor 0.8s cubic-bezier(0.68, 0.01, 0.01, 0.99) 0s
    infinite;
          animation: blinking-cursor 0.8s cubic-bezier(0.68, 0.01, 0.01, 0.99) 0s
    infinite;
}

@media only screen and (max-width: 572px) {
  .home-container {
    width: 100%;
    height: 600px;
    padding-left: 0%;
  }

  .home-image {
    display: none;
  }

  .collaboration {
    display: none;
  }

  .home-info {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  .home-text {
    display: block;
    padding-top: 30%;
    margin-top: 0%;
    width: 95%;
    padding-right: 0%;
    padding-left: 2%;
    padding-bottom: 5%;
    font-size: 18px;
  }

  .home-text p {
    min-width: 98%;
    padding-left: 2%;
    font-weight: 400;
  }
  .hatch-name {
    color:rgb(235, 189, 38);
    font-weight: 600;
    font-size: 26px;
  }

  .title-info {
    width: 100%;
    font-size: 20px;
  }

  .vertical-section {
    width: 100%;
    padding-bottom: 20%;
    padding-top: 20%;
  }

  .vertical-divider {
    display: none;
  }

  .home-sectional-mid {
    display: none;
  }

  .home-sectional-top {
    display: block;
    height: auto;
    margin-bottom: 3%;
  }

  .work-together-image {
    margin-left: 0px;
  }

  .sectional-image {
    width: 100%;
  }


  .team-image-mobile {
    display: block;
    width: 100%;
    padding-bottom: 50px;
  }

  .mobile-horizontal-divider {
    width: 100%;
    border: 2px solid rgb(230, 230, 230);
    display: block;
    margin-bottom: 5%;
  }
  
  .home-sectional-bottom {
    padding-bottom: 20%;
    padding-top: 20%;
    width: 100%;
    height: auto;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
  }

  .horizontal-section {
    display: block;
  }

  .horizontal-text {
    width: 100%;
    height: 100%;
  }

  .team-image {
    display: none;
  }

  .rotating-text {
    min-width: 50%;
    overflow-x: hidden;
    padding: 0%;
  }

  .rotating-text h1 {
    min-width: none;
    overflow-x: hidden;
  }


}

@-webkit-keyframes blinking-cursor {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes blinking-cursor {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.navbar a {
  color: black !important;
  text-decoration: none !important;
}

:root {
  font-size: 15px;
  --bg-color: #fff7dc;
}

.navbar {
  background: #fff7dc;
  background: var(--bg-color);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-content: center;
          align-content: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  height: 10vh;
  width: 100%;
  background-image: url("https://www.transparenttextures.com/patterns/az-subtle.png");
}

.logo {
  height: 50px;
  padding-right: 0px;
  padding-bottom: 0px;
  image-rendering: -webkit-optimize-contrast;
}

.logo-section,
.sections,
.navbar-buttons {
  width: 33%;
}

.logo-section {
  font-family: "Baloo 2", cursive;
  font-size: 1.8rem;
  color: black;
  display: -webkit-flex;
  display: flex;
  -webkit-align-content: center;
          align-content: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.logo-section p {
  margin-bottom: 0%;
  -webkit-align-self: center;
          align-self: center;
}

.sections,
.navbar-buttons {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-content: center;
          align-content: center;
  -webkit-justify-content: center;
          justify-content: center;
  letter-spacing: 1px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
}

.navbar-buttons {
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.navbar-section {
  height: 100%;
  width: auto;
  padding: 0px 20px 0px 20px;
  color: black;
}

.signup-button {
  background-color: rgb(39, 39, 51);
  /* margin-left: 20px; */
  color: white;
}

.navbar-button {
  border-radius: 10px;
  border: none;
  height: 40px;
  width: auto;
  letter-spacing: 1px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.9rem;
}

.navbar-button a {
  color: white;
}

@media only screen and (max-width: 572px) {


  .navbar {
    -webkit-justify-content: space-around;
            justify-content: space-around;
  }

  .navbar-section {
    padding: 0;
    font-size: 10px;
  }

  .mobile-about-tab {
    padding-right: 20px;
  }
  
  .mobile-profile-tab {
    padding-right: 10px;
  }

  .section {
    width: 50%;
  }

  .navbar-buttons {
    width: 40%;
  }

  .navbar-button {
    text-align: center;
  }

  .navbar-button p {
    margin-bottom: 0%;
  }

  .logo-section {
    width: 10%;
  }

  .logo-section p {
    display: none;
  }
}
.login-container {
    background-color: #fff7dc;
    min-height: 100vh;
}

.login-button {
    background-color: rgb(235, 189, 38);
    color: white;
    padding: 0px 20px 0px 20px;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    border-radius: 10px;
    border: none;
    height: 50px;
    letter-spacing: 1px;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 0.9rem;
    margin-right: 5%;
}

.signin-text {
    font-size: 50px;
    color: black;
    font-family: "Inter", sans-serif;
    font-weight: 600;

}

.connect-text {
    font-size: 20px;
    color: rgb(235, 189, 38);
}

.new-to-text {
    font-size: 30px;
    color: black;
}

h1 {
    font-family: "Inter", sans-serif;
    font-weight: 700;
}

h2 {
    font-family: "Inter", sans-serif;
    font-weight: 700;
}

.google-button {
    border-radius: 25px;
}
  
.signup-container {
    background-color: #fff7dc;
    min-height: 100vh;
    height: auto;
    padding-bottom: 2%;
}

.signup-button {
    background-color: rgb(235, 189, 38);
    color: white;
    padding: 0px 20px 0px 20px;
    width: auto;
}

h1 {
    font-family: "Inter", sans-serif;
    font-weight: 700;
}
  
.signup-text h1 {
    width: 60%;
    min-width: 400px;
}

.signup-text-gold {
    width: 60%;
    min-width: 400px;
    color: rgb(235, 189, 38);
}

.new-account-text h1 {
    color: "black";
    font-family: "Inter", sans-serif;
    font-weight: 700;
}

.form-label-text {
    font-family: "Inter", sans-serif;
    font-weight: 700;
    padding-top: 2.5%;
}

.profile-container {
  background-color: #fbfaf5;
  height: auto;
}

.profile-overview {
  background-color: #fbfaf5;
  border-color: rgb(235, 189, 38);
  border-width: 1px;
  border-style: solid;
  text-align: left;
  border-radius: 20px;
}

.profile-image {
  background-color: #fbfaf5;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  height: 70px;
  padding-left: 30px;
  border-bottom: solid;
  border-bottom-width: 1px;
  border-color: rgb(235, 189, 38);
}

.profile-info {
  background-color: #fbfaf5;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  height: 300px;
  padding-left: 30px;
}

.profile-siding {
  background-color: #fbfaf5;
  text-align: left;
  border-radius: 20px;
}

.edit-profile-link {
  background-color: #fbfaf5;
  border-color: rgb(235, 189, 38);
  border-width: 1px;
  border-style: solid;
  border-radius: 20px;
  height: 70px;
  padding-top: 20px;
  padding-left: 10px;
}

.members p {
  padding-left: 20px;
  white-space: nowrap;
  display: inline;
  color: black;
}

.a {
  text-decoration: none;
}

.network-container {
    background-color: #fff7dc;
    min-height: 1000px;
    width: 100%;
    padding-top: 0.5%;
    height: 100%;
}

.network-page-middle {
    padding-left: 3%;
}


.mobile-network-filter-container {
    display: none;
}

.network-filter-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    height: 50px;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
    width: -webkit-max-content;
    width: max-content;
    margin-bottom: 0.5%;
}

.multi-select-container {
    min-width: 40%;
    margin-right: 3%;
}

.friend-request-mobile {
    display: none;
}

.friends-container {
    height: auto;
    width: 100%;
    border-radius: 25px;
    text-align: left;
    background-color: #fbfaf5;
    padding-left: 2%;
    padding-top: 2%;
    padding-bottom: 1%;
    margin-bottom: 4%;
}
  
  .message-friend-button {
    text-align: center;
    height: 30px;
    width: auto;
    background-color: rgb(235, 189, 38);
    color: black;
    font-weight: 600;
    border-radius: 5px;
    border: none;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    max-height: 30px;
  }

  .filter-button {
    text-align: center;
    height: 38px;
    width: auto;
    background-color: rgb(235, 189, 38);
    color: black;
    font-weight: 600;
    border-radius: 5px;
    border: none;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    margin-left: 3%;
  }

  .recommended-friends {
    width: 85%;
    margin: 0% 0% 3% 0%;
    background-color: #fbfaf5;
    text-align: left;
    border-radius: 25px;
    padding: 2% 1% 1% 3%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}


.network-friends-row {
    display: -webkit-flex;
    display: flex;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    -webkit-align-items: center;
            align-items: center;
    -webkit-align-self: center;
            align-self: center;
    padding-left: 5%;
    width: 70%;
}

.network-friends-image {
    width: 10%;
    margin-right: 8%;
    border-radius: 50%;
}

.network-friends-info {
    width: auto;
}

.network-friends-name {
    margin-bottom: 0%;
    font-weight: 600;
}

.search-user-container {
    margin-bottom: 7%;
    display: -webkit-flex;
    display: flex;
}

.friend-interact-buttons {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    margin-right: 5%;
    -webkit-align-items: center;
            align-items: center;
    width: 30%;
}

.friend-interact-buttons button {
    margin-left: 10%;
}

.search-options {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    outline: none;
    border: #fbfaf5;
    background: #fbfaf5;
    margin-right: 3%;
    padding-left: 1%;
    border-radius: 4px;
    height: 38px;
}

.mobile-filters {
    display: inline;
}


.rmsc {
    --rmsc-main: none !important;
    --rmsc-hover: #f1f3f5;
    --rmsc-selected: --rmsc-gray !important;
    --rmsc-border: none !important;
    --rmsc-gray: #fbfaf5;
    --rmsc-bg: #fbfaf5 !important;
    --rmsc-p: 10px; /* Spacing */
    --rmsc-radius: 4px; /* Radius */
    --rmsc-h: 38px; /* Height */
}


@media only screen and (max-width: 572px) {

    .network-container {
        margin-top: 10%;
        padding-top: 3%;
    }

    .network-page-middle {
        padding-left: 0%;
    }

    .friends-container {
        width: 96%;
        margin-left: 2%;
    }

    .mobile-network-filter-container {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        height: 50px;
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
        -webkit-align-items: center;
                align-items: center;
        margin-top: 0.5%;
        width: 98%;
        margin-left: 1%;
        margin-right: 1%;
        margin-bottom: 0.5%;    
        height: 100px;
        padding-left: 0%;
    }

    .friend-interact-buttons {
        margin-right: 3%;

    }

    .network-filter-container {
        display: none;
    }
    
    .multi-select {
        margin-right: 2%;
    }


    .message-friend-button {
        padding: 0 2px 0 2px;
        text-align: center;
    }

    .friend-requests-mobile {
        display: block;
    }

    .mobile-filters {
        display: -webkit-flex;
        display: flex;
        margin-bottom: 4%;
        -webkit-align-self: flex-start;
                align-self: flex-start;
    }

}
.feed-page-container {
    background-color: #fff7dc;
    height: auto;
    min-height: 100vh;
    width: 100%;
    display: -webkit-flex;
    display: flex;
}
.feed-page-container-left {
    width: 6%;
}

.feed-page-container-middle {
    width: 65%;
    margin-top: 0.5%;
}

.feed-page-container-right {
    width: 29%;
    margin: 1% -2% 1% 5%;
}

.feed-container {
    width: 100%;
    margin-left: 5%;
    background-color: #fbfaf5;
    border-radius: 25px;
    padding-top: 1%;
    padding-bottom: 3%;
    margin-bottom: 3%;
}


.search-bar-container {
    width: 100%;
    margin-left: 5%;
    display: -webkit-flex;
    display: flex;
    border-radius: 10px;  
    background-color: #fff7dc;
    max-height: 50px;
}

.avatar-search-bar-holder {
    width: 15%;
    -webkit-align-self: center;
            align-self: center;
    margin-right: 2%;
    max-width: 30px;
    margin-left: 3%;
    border-style: none;
    background-color: #fbfaf5;
}

.avatar-search-bar {
    width: 175%;
}

.search-bar {
    width: 85%;
    padding-top: 1%;
    padding-bottom: 1%;
    background-color: #fbfaf5;
    border-style: hidden;
}

.search-bar:focus {
    outline: none;
}

.search-icon-container {
    -webkit-align-self: center;
            align-self: center;
    max-height: 50px;
    height: 100%;
    width: 4%;
    margin-left: 5%;
    margin-right: 2%;
    background-color: rgb(235, 189, 38);
    border-radius: 10%;
    border-style: none;
}

.search-icon {
    height: 100%;
    width: 100%;
}

.post-types {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    margin-left: 5%;
    margin-top: 3%;
    margin-bottom: 3%;
}

.post-types-left {
    width: 75%;
    max-width: 75%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: left;
            align-items: left;
    padding-left: 3%;
}

.post-types-button {
    border-radius: 10px;
    border: none;
    height: 40px;
    width: 100%;
    background-color: #fbfaf5;
    color: black;
    padding: 0px 20px 0px 20px;
    width: auto;
    margin-right: 3%;
    opacity: 0.5;
}

.add-post-button {
    border-radius: 10px;
    border: none;
    height: 40px;
    width: 20%;
    background-color: rgb(235, 189, 38);
    font-weight: 600;
    color: black;
    padding: 0px 20px 0px 20px;
    width: auto;
}

.post-types-button:active, .post-types-button:hover, .post-types-button:focus {
    opacity: 1.0;
}

.feed-post-user {
    display: -webkit-flex;
    display: flex;
    text-align: left;
    max-height: 75px;
}

.feed-post-user-info {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-self: center;
            align-self: center;
    width: 80%;
}

.feed-post-user-info p {
    margin-bottom: 0%;
}
 
.avatar-feed-button {
    border-style: none;
    background-color: transparent;
}

.avatar-feed-post {
    height: 75%;
    border-radius: 50%;
}

.startup-name-feed-post {
    display: -webkit-flex;
    display: flex;
    padding-left: 4%;
    margin-bottom: 1%;
}


.startup-name-feed-post h5 {
    margin-right: 3%;
}

.startup-logo {
    height: auto;
    max-height: 25px;
    width: auto;
    border-radius: 15px;
}

.feed-post-content {
    text-align: left;
    padding-left: 5%;
}

.roles-needed {
    text-align: left;
    padding-left: 4%;
    display: -webkit-flex;
    display: flex;
}

.interest-buttons-container {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    padding-left: 5%;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.save-button {
    border-radius: 10px;
    border-style: none;
    max-height: 30px;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    -webkit-align-self: center;
            align-self: center;
    font-weight: 600;
    background-color: #fbfaf5;
    color: black;
    margin-right: 3%;
}

.interest-buttons {
    border-radius: 10px;
    border-style: none;
    max-height: 30px;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    font-weight: 600;
    background-color: rgb(235, 189, 38);
    color: black;
    margin-right: 3%;
    padding: 1% 1% 4% 1%;
}

.recommended-friends {
    width: 85%;
    margin: 0% 0% 3% 0%;
    background-color: #fbfaf5;
    text-align: left;
    border-radius: 25px;
    padding: 2% 1% 1% 3%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}


.recommended-friends-row {
    display: -webkit-flex;
    display: flex;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    -webkit-align-items: center;
            align-items: center;
    -webkit-align-self: center;
            align-self: center;
    padding-left: 5%;
}

.recommended-friends-image {
    width: 25%;
    margin-right: 8%;
    border-radius: 50%;
}

.recommended-friends-info {
    width: auto;
}

.recommended-friends-name {
    margin-bottom: 0%;
    font-weight: 600;
}


.connect-friend-button {
    background-color: rgb(235, 189, 38);
    color: black;
    width: auto;
    border-radius: 5px;
    border: none;
    height: 20px;
    width: auto;
    letter-spacing: 1px;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 0.9rem;
}

.create-post-input {
    width: 100%;
    background-color: rgb(255, 253, 243);
    border-radius: 5px;
    border-style: none;
    margin-bottom: 3%;
}
  
textarea:focus, input:focus {
    outline: none;
}

.select-pod {
    background-color: #ffffff;
    color: black;
    width: auto;
    padding: 5px;
    border-radius: 5px;
    border: solid;
    border-width: 1px;
    height: auto;
    width: auto;
    font-weight: 400;
    margin-bottom: 5%;
}

.optional-label {
    margin-bottom: 0.5%;
    font-size: 12px;
    padding-left: 2%;
}

.comments {
    border-top-style: solid;
    border-width: 1px;
    border-color: rgb(235, 189, 38);
    margin-top: 1%;
    padding-top: 1%;
    text-align: left;
    padding-left: 8%;
    width: 100%;
}

.comment-input {
    margin-bottom: 2%;
}

.comment-row {
    display: -webkit-flex;
    display: flex;
    margin-left: 2%;
    max-height: 50px;
    margin-bottom: 3%;
}

.comments-user-image {
    margin-top: 1%;
    border-radius: 50%;
    width: 4%;
    height: 4%;
}

.comment-right {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-left: 3%;
    width: 80%;
    margin-top: 0%;
    padding-top: 0%;
}

.comment-user {
    margin-bottom: 1%;
    font-weight: 500;
}

.comment-message {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    margin-bottom: 0%;
    color: slategrey;
}


@media only screen and (max-width: 572px) {
    .feed-page-container {
        padding-bottom: 0;
        margin-bottom: 0;
        margin-top: 10%;
    }

    .feed-page-container-left {
        display: none;
    }
    .feed-page-container-right {
        display: none;
    }
    .feed-page-container-middle {
        width: 100%;
        padding: 2% 5% 70px 5%;
    }

    .feed-container {
        width: 100%;
        margin-left: 0%;
        padding-left: 0%;
        background-color: #fbfaf5;
        border-radius: 25px;
    }
    .search-bar-container {
        width: 100%;
        margin-left: 0%;
        display: -webkit-flex;
        display: flex;
        border-radius: 10px;  
        background-color: #fff7dc;
        max-height: 50px;
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
    }

    .post-types {
        display: -webkit-flex;
        display: flex;
        width: 100%;
        margin-left: 0%;
        margin-top: 3%;
        margin-bottom: 3%;
    }

    .post-types-button {
        font-size: 12px;
        padding: 0px 5px 0px 5px;
        height: 30px;
    }

    .add-post-button {
        height: 30px;
        font-size: 12px;
        padding: 0px 5px 0px 5px;
    }

    .avatar-search-bar {
        width: 100%;
    }

    .search-icon-container {
        width: 7%;
    }

    .avatar-feed-button {
        width: 35%;
    }

    .comment-row {
        max-height: -webkit-fit-content;
        max-height: -moz-fit-content;
        max-height: fit-content;
    }

    .comment-right p {
        margin-bottom: 0%;
    }

    .feed-post-user {
        height: 75px;
    }

    .avatar-feed-post {
        width: 50px;
        height: 50px;
    }

    .recommended-friends {
        width: 96%;
        margin: 2% 0% 3% 2%;
        border-radius: 25px;
        padding: 2% 1% 1% 3%;
    }

    .comments-user-image {
        width: 25px;
        height: 25px;
    }
}
.side-nav-container {
    width: 6%;
}

.room-nav {
    width: 6%;
    background-color: rgb(235, 189, 38);
    margin-left: 1%;
    border-radius: 25px;
    margin-top: 0.5%;
    height: 98%;
    bottom: 0;
    top: 0;
    z-index: 1; 
    position: fixed;
    min-width: 40px;
}

.nav-icon-group {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    height: auto;
}

.icon-nav {
    width: 50%;
    height: 100%;
    -webkit-align-self: center;
            align-self: center;
    color: black;
}

.nav-link {
    width: 100%;
    border-style: none;
    background-color: rgb(235, 189, 38);
    margin-bottom: 20%;
}

.bottom-tab-nav-container {
    background-color: rgb(235, 189, 38);
    min-height: 60px;
    bottom: 0;
    width: 100%;   
    position: fixed;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.bottom-tab-nav {
    width: 100%;
    bottom: 0;
    z-index: 1; 
}

.bottom-tab-icon-group {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding-left: 5%;
    padding-right: 5%;
}

.bottom-tab-nav-link {
    width: 100%;
    border-style: none;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    background-color: rgb(235, 189, 38);
}

.bottom-tab-nav-link p {
    margin-bottom: 0;
}

.bottom-tab-icon-nav {
    width: 100%;
    -webkit-align-self: center;
            align-self: center;
    color: black;
}

.hover-text {
    color: black;
    margin-bottom: 0;
    display: none;
    font-size: 10px;
}

.nav-link:hover p{
    display: block;
}

.mobile-nav-bar-container {
    background-color: rgb(235, 189, 38);
    width: 100%;
    height: 5%;
    top: 0;
    z-index: 2;
    position: fixed;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.mobile-tab-nav {
    width: 30%;
    background-color: rgb(235, 189, 38);
    height: 95%;
    bottom: 0;
    z-index: 2; 
    position: fixed;
    min-width: 40px;
    padding-left: 1%;
    padding-top: 3%;
}

.mobile-tab-icon-group {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;

}

.mobile-tab-nav-link {
    width: 100%;
    border-style: none;
    display: -webkit-flex;
    display: flex;
    text-align: left;
    background-color: rgb(235, 189, 38);
    padding-bottom: 25%;
}

.mobile-tab-nav-link p {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 20px;
    width: 60%;
}

.mobile-tab-icon-nav {
    width: 40%;
    -webkit-align-self: center;
            align-self: center;
    color: black;
}

.mobile-nav-toggle-button {
    background-color: rgb(235, 189, 38);
    border: none;
}


.profile-container {
  background-color: #fff7dc;
  height: auto;
  min-height: 592px;
  padding-top: 0.5%;
  width: 100%;
}

.profile-page-right {
  padding-right: 4%;
  padding-left: 3%;
}

.profile-page-middle {
  padding-left: 3%;
}

.profile-header {
  border: 1px solid rgb(226, 226, 226);
  height: 450px;
  width: 100%;
  border-radius: 25px;
  margin-bottom: 5%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  background-color: #fbfaf5;
}

.profile-top {
  margin-left: -1.4%;
  height: 45%;
  width: 102.85%;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
}

.profile-bg-image {
  width: 100%;
  height: 100%;
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
}

.profile-bottom {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.profile-description {
  height: 55%;
  width: auto;
  margin-left: 30%;
  text-align: left;
  padding-top: 1%;
}

.profile-description p {
  margin-bottom: 0%;
}

.profile-avatar {
  background-color: lemonchiffon;
  margin-left: 0 auto;
  border-radius: 50%;
  width: 100%;
  border: 6px solid white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}

.profile-avatar-holder {
  position: absolute;
  z-index: 1;
  height: 65%;
  width: 14%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-left: 2%;
}

.edit-profile-button-desktop {
  text-align: center;
  height: 20%;
  display: -webkit-flex;
  display: flex;
  background-color: rgb(235, 189, 38);
  color: black;
  padding: 0px 0px 0px 10px;
  border-radius: 5px;
  border: none;
  height: 20px;
  width: 130px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.9rem;
}
.edit-profile-button-mobile {
  display: none;
}

.edit-image {
  height: 100%;
  margin-right: 14px;
  padding-bottom: 3px;
  padding-top: 3px;
}

.number-connections {
  font-size: 16px;
  color:rgb(235, 189, 38);
  font-weight: 500;
  padding-bottom: 2%;
}

.profile-links {
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-top: 1%;
}

.profile-links a{
  padding-right: 6%;
}

.about-section {
  border: 1px solid rgb(226, 226, 226);
  height: auto;
  width: 100%;
  border-radius: 25px;
  text-align: left;
  padding-top: 2%;
  background-color: #fbfaf5;
  margin-bottom: 5%;
}

.about-section h4 {
  padding-left: 1%;
  margin-bottom: 2%;
}
.about-section p {
  padding-left: 1%;
}

.about-header {
  display: -webkit-flex;
  display: flex;
  margin-bottom: 0.5%;
}

.about-title {
  width: 95%;
}

.about-edit-cancel {
  margin-bottom: 0;
}

.passions-section {
  border: 1px solid rgb(226, 226, 226);
  height: auto;
  width: 100%;
  border-radius: 25px;
  text-align: left;
  padding-left: 2%;
  margin-bottom: 5%;
  padding-top: 2%;
  background-color: #fbfaf5;
}

.passions-section h4 {
  padding-left: 1%;
  margin-bottom: 2%;
}


.myskills-section {
  border: 1px solid rgb(226, 226, 226);
  height: auto;
  width: 100%;
  border-radius: 25px;
  text-align: left;
  background-color: #fbfaf5;
  padding-left: 2%;
  margin-bottom: 5%;
  padding-top: 2%;
}

.myskills-section p {
  padding-left: 10%;
}
.myskills-section h4 {
  padding-left: 2%;
  margin-bottom: 4%;
}

.additional-section {
  border: 1px solid rgb(226, 226, 226);
  height: auto;
  width: 100%;
  border-radius: 25px;
  text-align: left;
  background-color: #fbfaf5;
  padding-top: 2%;
  padding-left: 2%;
  margin-bottom: 5%;
}

.additional-section h4 {
  padding-left: 2%;
  margin-bottom: 4%;
}

.edit-about-me {
  text-align: center;
  background-color: rgb(235, 189, 38);
  color: black;
  border-radius: 5px;
  border: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: auto;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.9rem;
}

.form-inline .form-control {
  width:auto;
}
.form-inline .form-group {
  display: inline-block;
}

.profile-overview {
  background-color: #fbfaf5;
  border-color: rgb(235, 189, 38);
  border-width: 1px;
  border-style: solid;
  text-align: left;
  border-radius: 20px;
}

.profile-image {
  background-color: #fbfaf5;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  height: 70px;
  padding-left: 30px;
  border-bottom: solid;
  border-bottom-width: 1px;
  border-color: rgb(235, 189, 38);
}

.profile-info {
  background-color: #fbfaf5;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  height: 300px;
  padding-left: 30px;
}

.profile-siding {
  background-color: #fbfaf5;
  text-align: left;
  border-radius: 20px;
}

.edit-profile-link {
  background-color: #fbfaf5;
  border-color: rgb(235, 189, 38);
  border-width: 1px;
  border-style: solid;
  border-radius: 20px;
  height: 70px;
  padding-top: 20px;
  padding-left: 10px;
}

.members p {
  padding-left: 20px;
  white-space: nowrap;
  display: inline;
  color: black;
}

.a {
  text-decoration: none;
}


.message-button {
  text-align: center;
  height: 20%;
  background-color: rgb(235, 189, 38);
  color: black;
  font-weight: 600;
  border-radius: 5px;
  border: none;
  max-height: 30px;
  padding: 1% 10%;
}

.add-skill-button {
  text-align: center;
  height: 20%;
  background-color: rgb(235, 189, 38);
  color: black;
  font-weight: 600;
  border-radius: 5px;
  border: none;
  margin-top: 1%;
  margin-bottom: 3%;
  max-height: 30px;
  padding: 1%;
}

.delete-icon-button {
  background-color: #fbfaf5;
  border-style: none;
  padding-bottom: 10%;
}

.skill-row {
  margin-bottom: 10%;
  max-height: 22px;
  -webkit-align-content: center;
          align-content: center;
  display: -webkit-flex;
  display: flex;
}

.skill-text {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding-right: 5%;
}

.trash-icon {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.portal-bottom-tab-nav {
  display: none;
}

.mobile-profile-pods {
  display: none;
}

.desktop-profile-pods {
  display: none;
}

@media only screen and (max-width: 572px) {
  .side-nav {
    display: none;
  }

  .profile-description {
    margin-left: 25%;
    width: 80%;
  }

  .desktop-profile-pods {
    display: none;
  }

  .mobile-profile-pods {
    display: block;
  }

  .portal-bottom-tab-nav {
    display: block;
  }

  .profile-container {
    margin-right: 0%;
    padding-right: 0%;
    margin-top: 10%;
  }

  .profile {
    background-color: #fff7dc;
    padding: 2% 5% 1% 5%;
    margin-right: 0%;
  }

  .profile-page-right {
    padding-right: 0%;
    padding-left: 0%;
    padding-bottom: 50px;
  }

  .profile-page-middle {
    padding-left: 0%;
  }

  .profile-links {
    display: block;
  }

  .edit-profile-button-desktop {
    display: none;
  }

  .edit-profile-button-mobile {
    text-align: center;
    height: 20%;
    display: -webkit-flex;
    display: flex;
    background-color: rgb(235, 189, 38);
    color: black;
    padding: 0px;
    border-radius: 5px;
    border: none;
    height: 24px;
    width: 24px;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 0.9rem;
  }


}


.pods-page-container {
  background-color: #fff7dc;
  height: auto;
  min-height: 100vh;
  width: 100%;
  padding-top: 0.5%;
  
}

.connect-button {
  background-color: rgb(235, 189, 38);
  color: black;  
  padding: 0px 20px 0px 20px;
  width: auto;
  border-radius: 20px;
  border: none;
  height: 20px;
  width: auto;
  letter-spacing: 1px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.9rem;
}

.pod-members-container{
  height: auto;
  width: 100%;
  border-radius: 25px;
  text-align: left;
  padding-top: 2%;
  background-color: #fbfaf5;
  margin-bottom: 5%;
}

.pod-members-container h5 {
  color: black;
  padding-left: 20px;
}

.about-pod-section {
  height: auto;
  width: 100%;
  border-radius: 25px;
  text-align: left;
  padding-top: 2%;
  background-color: #fbfaf5;
  margin-bottom: 5%;
}

.about-pod-section h4 {
  padding-left: 1%;
  margin-bottom: 2%;
}
.about-pod-section p {
  padding-left: 1%;
}

.pods-header {
  height: 350px;
  width: 100%;
  border-radius: 25px;
  margin-bottom: 5%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  background-color: #fbfaf5;
}

.pods-top {
  margin-left: -1.4%;
  height: 45%;
  width: 102.85%;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
}

.pods-bg-image {
  width: 100%;
  height: 100%;
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
}

.pods-bottom {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.pods-description {
  height: 55%;
  width: auto;
  margin-left: 30%;
  text-align: left;
  padding-top: 1%;
  
}

.pods-avatar {
  background-color: lemonchiffon;
  margin-left: 0 auto;
  border-radius: 50%;
  width: 100%;
  height: auto;
  border: 3px solid white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}

.pods-avatar-holder {
  position: absolute;
  z-index: 1;
  height: 55%;
  width: 13%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-left: 2%;
}

.edit-pods-button {
  text-align: center;
  height: 20%;
  display: -webkit-flex;
  display: flex;
  background-color: rgb(235, 189, 38);
  color: black;
  padding: 0px 0px 0px 10px;
  border-radius: 5px;
  border: none;
  height: 20px;
  width: 130px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.9rem;
}

.edit-image {
    height: 100%;
    margin-right: 14px;
    padding-bottom: 3px;
    padding-top: 3px;
}

.pods-links {
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }
  
.pods-links a{
  padding-right: 6%;
}

.pod-member {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 1.5%;
}

.pod-member-info {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-left: 2%;
  width: 80%;
}

.pod-member-image {
  margin-left: 1%;
  height: 100%;
  max-height: 35px;
}

.similar-startup-image-container {
  width: 20%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.similar-startup-image {
  height: 100%;
  max-height: 40px;
  max-width: 100%;
}

.similar-startups {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 5%;
  padding-left: 2%;  
}

.pods-list-container {
  height: auto;
  text-align: left;
  padding-top: 2%;
  background-color: #fbfaf5;
  padding-bottom: 3%;
}

.select-pod-profile {
  border-style: none;
  width: 100%;
  background-color: #fbfaf5;
}

.pod-display-summary {
  height: 10%;
  display: -webkit-flex;
  display: flex;
}

.pod-list-avatar {
  border-radius: 50%;
  width: 15%;
  -webkit-align-self: center;
          align-self: center;
  margin-left: 3%;
}

.pod-list-text-display {
  padding-left: 4%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: left;
  padding-top: 4%;
}

.pod-list-avatar-name {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 0%;
}

.pod-description {
  font-size: 10px;
  overflow: hidden;
  white-space: nowrap;
  max-width: 28ch;
  text-overflow: ellipsis;
}

.create-pod-button {
  border-radius: 10px;
  border: none;
  height: 40px;
  width: 20%;
  background-color: rgb(235, 189, 38);
  font-weight: 600;
  color: black;
  padding: 0px 20px 0px 20px;
  width: auto;
}

.pod-posts-container {
  height: auto;
  width: 100%;
  border-radius: 25px;
  text-align: left;
  padding-top: 2%;
  padding-bottom: 5%;
  background-color: #fbfaf5;
  margin-bottom: 5%; 
}

.roles-wanted-input {
  width: 100%;
  background-color: rgb(255, 253, 243);
  border-radius: 5px;
  border-style: none;
  margin-bottom: 3%;
}

.pods-posts-header {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.add-pod-container {
  border: 1px solid rgb(226, 226, 226);
  height: auto;
  width: 100%;
  border-radius: 25px;
  text-align: left;
  background-color: #fbfaf5;
  padding-top: 2%;
  padding-left: 2%;
  margin-bottom: 5%;
  padding-bottom: 3%;
}

.add-pod-container h4 {
  padding-left: 2%;
  margin-bottom: 4%;
}


.add-pod-post-button {
  border-radius: 10px;
  border: none;
  height: 30px;
  width: 20%;
  background-color: rgb(235, 189, 38);
  font-weight: 600;
  color: black;
  padding: 0px 10px 0px 10px;
  width: auto;
}

@media only screen and (max-width: 572px) {
  .pods-page-container {
    margin-top: 10%;
  }
}
.pods-page-container {
    background-color: #fff7dc;
    height: auto;
    padding-top: 0.5%;
}

.connect-button {
    background-color: rgb(235, 189, 38);
    color: black;  
    padding: 0px 20px 0px 20px;
    width: auto;
    border-radius: 20px;
    border: none;
    height: 20px;
    width: auto;
    letter-spacing: 1px;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 0.9rem;
}

.members h5 {
    color: black;
    padding-left: 20px;
}

.about-pod-section {
    height: auto;
    width: 100%;
    border-radius: 25px;
    text-align: left;
    padding-top: 2%;
    background-color: #fbfaf5;
    margin-bottom: 5%;
  }
  
  .about-pod-section h4 {
    padding-left: 1%;
    margin-bottom: 2%;
  }
  .about-pod-section p {
    padding-left: 1%;
  }

  .members-pod-section {
    height: auto;
    width: 100%;
    border-radius: 25px;
    text-align: left;
    padding-top: 2%;
    background-color: #fbfaf5;
    margin-bottom: 5%;
  }
  
  .members-pod-section h4 {
    padding-left: 1%;
    margin-bottom: 2%;
  }
  .members-pod-section p {
    padding-left: 1%;
  }
.chat-room-overview {
    display: -webkit-flex;
    display: flex;
    background-color: #ffffff;
}

.chat-room-side-nav {
  width: 6%;
}

.chat-room-left {
    width: 17%;
    padding-top: 2%;
    text-align: left;
    background-color: #f9f9fc;
    padding-left: 1.5%;
}

.chat-room-right {
    width: 16%;
    margin-top: 5%;
    padding-top: 3%;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    background-color: #f4f4fa;
    margin-right: 1%;
}

.chat-room-right p {
    font-size: 12px;
    font-weight: 600;
}


.chat-room-container body {
  background-color: #282c34;
}

.chat-room-container {
  text-align: center;
  max-width: 728px;
  margin: 0 auto;
  width: 60%;
}

.chat-room-container header {
  height: 10vh;
  min-height: 50px;
  font-size: 50px;
  color: black;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  position: fixed;
  width: 100%;
  max-width: 728px;
  top: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  z-index: 99;
  padding: 10px;
  box-sizing: border-box;
}

.chat-room-container section {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  min-height: 100vh;
  background-color: #ffffff;
}

.chat-room-container main {
  padding: 10px;
  height: 80vh;
  margin: 10vh 0 10vh;
  overflow-y: scroll;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
}

.chat-room-container main::-webkit-scrollbar {
  width: 0.25rem;
}

.chat-room-container main::-webkit-scrollbar-track {
  background: #e5e5ea;
}

.chat-room-container main::-webkit-scrollbar-thumb {
  background: rgb(235, 189, 38);
}

.chat-room-container form {
  height: 8vh;
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 728px;
  display: -webkit-flex;
  display: flex;
  font-size: 1.5rem;
  padding-left: 2%;
  padding-right: 2%;
  border-radius: 25px;
  margin-bottom: 2%;
}

.chat-room-container form button {
  width: 10%;
  background-color: rgb(235, 189, 38);
}


.chat-room-container input {
  line-height: 1.5;
  width: 100%;
  font-size: 1.0rem;
  background: #e5e5ea;
  color: black;
  outline: none;
  border: none;
  padding: 3% 4%;
  border-radius: 25px;
  margin-right: 2%
}

.chat-room-container button {
  background-color: #282c34;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  width: auto;
  font-size: 1.2rem;
  border-radius: 25px;
}

.chat-room-container button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.chat-room-container ul, .chat-room-container li {
  text-align: left;
  list-style: none;
}

.chat-room-container p {
  max-width: 728px;
  margin-bottom: 12px;
  line-height: 24px;
  padding: 10px 20px;
  border-radius: 25px;
  position: relative;
  color: white;
  text-align: center;
}

.message {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.sent {
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse;
  -webkit-align-self: flex-end;
          align-self: flex-end;
}

.sent p {
  color: white;
  background: rgb(235, 189, 38);

}
.received p {
  background: #e5e5ea;
  color: black;
}

.chat-room-container img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 2px 5px;
}

.group-members-header {
    width: 90%;
    margin-left: 5%;
    border-bottom-style: solid;
    border-bottom-width: 1.5px;
    border-bottom-color: lightgray;
    height: 33%;
}

.group-chat-photos {
    padding-top: 5%;
    width: 90%;
    height: 33%;
    margin-left: 5%;
    border-bottom-style: solid;
    border-bottom-width: 1.5px;
    border-bottom-color: lightgray;
    text-align: left;
}

.group-chat-attachments {
    padding-top: 5%;
    width: 90%;
    height: 33%;
    margin-left: 5%;
    text-align: left;
}

.select-friend {
  border-style: none;
  background-color: #f9f9fc;
  width: 100%;
  min-height: 60px;

}

.select-friend:focus {
  background: rgb(0,0,0);
  background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(233,205,115,0.99) 1%, rgba(255,247,221,1) 60%, rgba(250,248,236,1) 100%);
}

.friend-display {
  height: 10%;
  display: -webkit-flex;
  display: flex;
}

.friend-avatar {
  border-radius: 50%;
  width: 18%;
  -webkit-align-self: center;
          align-self: center;
}

.friend-text-display {
  padding-left: 4%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-top: 6.5%;
  text-align: left;
}

.friend-avatar-name {
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 0%;
}

.friend-latest-message {
  font-size: 10px;
  overflow: hidden;
  white-space: nowrap;
  max-width: 28ch;
  text-overflow: ellipsis;
}

.show-message-container {
  display: none;
}

.show-messages {
  display: none;
}

@media only screen and (max-width: 572px) {
  .chat-room-right {
    display: none;
  }

  .chat-room-side-nav {
    display: none;
  }

  .chat-room-left {
    width: 100%;
    z-index: 1;
    bottom: 0;
    margin-bottom: 60px;
  }

  .chat-room-container {
    width: 100%;
    margin-top: 10%;
  }

  .chat-room-header {
    background-color: rgb(235, 189, 38);
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    height: 5%;
  }

  .chat-room-container form {
    margin-bottom: 5%;
  }

  .logo-section-chat-room {
    display: none;
  }

  .show-message-container {
    display: -webkit-flex;
    display: flex;
    text-align: center;
    height: 75%;
    -webkit-align-self: center;
            align-self: center;
    margin-left: 2%;
  }

  .show-messages {
    display: inline-block;
  }

  .search-messages {
    display: none;
  }

  .friend-latest-message {
    max-width: 24ch;
  }

}
.contactus-page-container {
  height: 100%;
  background-color: #fff7dc;
  background-image: url("https://www.transparenttextures.com/patterns/az-subtle.png");
}

.page-landing {
  padding-top: 1%;
  width: 80%;
  height: 100%;
  font-family: "Inter", sans-serif;
  display: -webkit-flex;
  display: flex;
  margin-left: 10%;
  margin-right: 10%;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

.landing-left {
  text-align: left;
  font-family: "Inter", sans-serif;
  margin-bottom: 5%;
}

.landing-right-contact {
  margin-top: 7%;
  margin-bottom: 10%;
}

.page-heading h2 {
  margin-left: 10%;
  padding-bottom: 1%;
}

.contactus-image {
  width: 100%;
}

.input-form {
  background-color: rgb(37, 36, 55);
  height: auto;
  width: 80%;
  border-radius: 20px;
  padding: 5%;
  color: white;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.input-form h4 {
  width: 100%;
  text-align: left;
}

.input-form input {
  height: 40px;
}

.input-field {
  width: 100%;
  background-color: rgb(255, 253, 243);
  border-radius: 5px;
  border-style: none;
  padding-left: 4%;
}

.contactus-button {
  border-radius: 10px;
  border: none;
  height: 40px;
  width: 20%;
  letter-spacing: 1px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.9rem;
  background-color: rgb(235, 189, 38);
  color: white;
  padding: 0px 20px 0px 20px;
  width: auto;
  margin-top: 5%;
}

@media only screen and (max-width: 572px) {
  .landing-right-contact {
    display: none;
  }

  .landing-left {
    width: 100%;
  }
  
  .page-landing {
    width: 90%;
    min-height: 100vh;
    display: -webkit-flex;
    display: flex;
    margin-left: 5%;
    margin-right: 5%;
    -webkit-justify-content: center;
            justify-content: center;
  }

  .input-form {
    width: 100%;
  }
}

