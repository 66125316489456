@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap");

.home-container {
  height: 500px;
  background-color: var(--bg-color);
  display: flex;
  width: 100%;
  background-image: url("https://www.transparenttextures.com/patterns/az-subtle.png");
}

h1 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
}

.home-text h1 {
  width: 60%;
  min-width: 400px;
}

.home-text p {
  width: 80%;
  min-width: 500px;
}

.home-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 10%;
  text-align: left;
  width: 40%;
}

.collaboration {
  height: 70%;
  width: 50%;
}

.home-image {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.get-started-button {
  background-color: rgb(235, 189, 38);
  color: white;
  padding: 0px 20px 0px 20px;
  width: auto;
}

.home-stat {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  padding-top: 15px;
}

.home-stat p {
  letter-spacing: 1px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
}

.home-stat h1 {
  font-weight: 900;
  padding: 0 !important;
  margin: 0 !important;
}


.home-user-details {
  background-color: rgb(235, 189, 38);
  height: 140px;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-evenly;
}

.title-info span {
  color: rgb(235, 189, 38);
}

.footer {
  background-color: rgb(37, 36, 55);
  height: 100px;
  bottom: 0;
  width: 100%;
  position: relative;
  background-image: url("https://www.transparenttextures.com/patterns/white-diamond-dark.png");
}

.company-name-footer {
  color: white;
  text-align: left;
  padding-left: 110px;
  padding-top: 5px;
  font-size: 20px;
}

.footer-row {
  display: flex;
}
.terms {
  color: grey;
  text-align: left;
  padding-left: 110px;
  font-size: 14px;
}
.privacy-policy {
  color: grey;
  text-align: left;
  padding-left: 20px;
  font-size: 14px;
}

.vertical-divider {
  height: 100%;
  border: 2px solid rgb(230, 230, 230);
}

.sectional-container {
  width: 90%;
  height: 80%;
  font-family: "Inter", sans-serif;
}

.home-info {
  background-color: rgb(255, 253, 243);
  height: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("https://www.transparenttextures.com/patterns/connected.png");
}

.title-info {
  letter-spacing: 1px;
  font-size: 30px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  padding-top: 25px;
  height: 10%;
  width: 100%;
}

.sectional-image {
  height: 45%;
  margin-bottom: 30px;
}

.work-together-image {
  margin-left: -60px;
}

.vertical-section {
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
}

.sectional-container h2,
.sectional-container a,
.sectional-container p {
  width: 100%;
  text-align: left;
}

.sectional-container h2 {
  font-weight: 700;
  font-size: 1.5em;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.sectional-container a {
  font-weight: 700;
}

.home-sectional-top {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 55%;
  width: 100%;
}

.home-sectional-mid {
  height: 7%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.home-sectional-bottom {
  height: 38%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.horizontal-section {
  width: 80%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.horizontal-text {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 50%;
  height: 50%;
}

.horizontal-text p {
  max-width: 80%;
}

.horizontal-divider {
  width: 40%;
  border: 2px solid rgb(230, 230, 230);
}

.mobile-horizontal-divider {
  display: none;
}

.sectional-button {
  border-radius: 10px;
  border: none;
  height: 40px;
  width: 20%;
  letter-spacing: 1px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.9rem;
  background-color: rgb(37, 36, 55);
  color: white;
  padding: 0px 20px 0px 20px;
  width: auto;
}

.sectional-button a {
  letter-spacing: 1px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.9rem;
}

.rotating-text {
  min-width: 500px;
}
.rotating-text h1 {
  width: 100%;
}

.team-image-mobile {
  display: none;
}


.react-rotating-text-cursor {
  animation: blinking-cursor 0.8s cubic-bezier(0.68, 0.01, 0.01, 0.99) 0s
    infinite;
}

@media only screen and (max-width: 572px) {
  .home-container {
    width: 100%;
    height: 600px;
    padding-left: 0%;
  }

  .home-image {
    display: none;
  }

  .collaboration {
    display: none;
  }

  .home-info {
    height: fit-content;
  }

  .home-text {
    display: block;
    padding-top: 30%;
    margin-top: 0%;
    width: 95%;
    padding-right: 0%;
    padding-left: 2%;
    padding-bottom: 5%;
    font-size: 18px;
  }

  .home-text p {
    min-width: 98%;
    padding-left: 2%;
    font-weight: 400;
  }
  .hatch-name {
    color:rgb(235, 189, 38);
    font-weight: 600;
    font-size: 26px;
  }

  .title-info {
    width: 100%;
    font-size: 20px;
  }

  .vertical-section {
    width: 100%;
    padding-bottom: 20%;
    padding-top: 20%;
  }

  .vertical-divider {
    display: none;
  }

  .home-sectional-mid {
    display: none;
  }

  .home-sectional-top {
    display: block;
    height: auto;
    margin-bottom: 3%;
  }

  .work-together-image {
    margin-left: 0px;
  }

  .sectional-image {
    width: 100%;
  }


  .team-image-mobile {
    display: block;
    width: 100%;
    padding-bottom: 50px;
  }

  .mobile-horizontal-divider {
    width: 100%;
    border: 2px solid rgb(230, 230, 230);
    display: block;
    margin-bottom: 5%;
  }
  
  .home-sectional-bottom {
    padding-bottom: 20%;
    padding-top: 20%;
    width: 100%;
    height: auto;
    justify-content: flex-start;
  }

  .horizontal-section {
    display: block;
  }

  .horizontal-text {
    width: 100%;
    height: 100%;
  }

  .team-image {
    display: none;
  }

  .rotating-text {
    min-width: 50%;
    overflow-x: hidden;
    padding: 0%;
  }

  .rotating-text h1 {
    min-width: none;
    overflow-x: hidden;
  }


}

@keyframes blinking-cursor {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
