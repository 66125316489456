.profile-container {
  background-color: #fbfaf5;
  height: auto;
}

.profile-overview {
  background-color: #fbfaf5;
  border-color: rgb(235, 189, 38);
  border-width: 1px;
  border-style: solid;
  text-align: left;
  border-radius: 20px;
}

.profile-image {
  background-color: #fbfaf5;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  height: 70px;
  padding-left: 30px;
  border-bottom: solid;
  border-bottom-width: 1px;
  border-color: rgb(235, 189, 38);
}

.profile-info {
  background-color: #fbfaf5;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  height: 300px;
  padding-left: 30px;
}

.profile-siding {
  background-color: #fbfaf5;
  text-align: left;
  border-radius: 20px;
}

.edit-profile-link {
  background-color: #fbfaf5;
  border-color: rgb(235, 189, 38);
  border-width: 1px;
  border-style: solid;
  border-radius: 20px;
  height: 70px;
  padding-top: 20px;
  padding-left: 10px;
}

.members p {
  padding-left: 20px;
  white-space: nowrap;
  display: inline;
  color: black;
}

.a {
  text-decoration: none;
}
