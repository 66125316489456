.chat-room-overview {
    display: flex;
    background-color: #ffffff;
}

.chat-room-side-nav {
  width: 6%;
}

.chat-room-left {
    width: 17%;
    padding-top: 2%;
    text-align: left;
    background-color: #f9f9fc;
    padding-left: 1.5%;
}

.chat-room-right {
    width: 16%;
    margin-top: 5%;
    padding-top: 3%;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    background-color: #f4f4fa;
    margin-right: 1%;
}

.chat-room-right p {
    font-size: 12px;
    font-weight: 600;
}


.chat-room-container body {
  background-color: #282c34;
}

.chat-room-container {
  text-align: center;
  max-width: 728px;
  margin: 0 auto;
  width: 60%;
}

.chat-room-container header {
  height: 10vh;
  min-height: 50px;
  font-size: 50px;
  color: black;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  position: fixed;
  width: 100%;
  max-width: 728px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 99;
  padding: 10px;
  box-sizing: border-box;
}

.chat-room-container section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  background-color: #ffffff;
}

.chat-room-container main {
  padding: 10px;
  height: 80vh;
  margin: 10vh 0 10vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.chat-room-container main::-webkit-scrollbar {
  width: 0.25rem;
}

.chat-room-container main::-webkit-scrollbar-track {
  background: #e5e5ea;
}

.chat-room-container main::-webkit-scrollbar-thumb {
  background: rgb(235, 189, 38);
}

.chat-room-container form {
  height: 8vh;
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 728px;
  display: flex;
  font-size: 1.5rem;
  padding-left: 2%;
  padding-right: 2%;
  border-radius: 25px;
  margin-bottom: 2%;
}

.chat-room-container form button {
  width: 10%;
  background-color: rgb(235, 189, 38);
}


.chat-room-container input {
  line-height: 1.5;
  width: 100%;
  font-size: 1.0rem;
  background: #e5e5ea;
  color: black;
  outline: none;
  border: none;
  padding: 3% 4%;
  border-radius: 25px;
  margin-right: 2%
}

.chat-room-container button {
  background-color: #282c34;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  width: auto;
  font-size: 1.2rem;
  border-radius: 25px;
}

.chat-room-container button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.chat-room-container ul, .chat-room-container li {
  text-align: left;
  list-style: none;
}

.chat-room-container p {
  max-width: 728px;
  margin-bottom: 12px;
  line-height: 24px;
  padding: 10px 20px;
  border-radius: 25px;
  position: relative;
  color: white;
  text-align: center;
}

.message {
  display: flex;
  align-items: center;
}

.sent {
  flex-direction: row-reverse;
  align-self: flex-end;
}

.sent p {
  color: white;
  background: rgb(235, 189, 38);

}
.received p {
  background: #e5e5ea;
  color: black;
}

.chat-room-container img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 2px 5px;
}

.group-members-header {
    width: 90%;
    margin-left: 5%;
    border-bottom-style: solid;
    border-bottom-width: 1.5px;
    border-bottom-color: lightgray;
    height: 33%;
}

.group-chat-photos {
    padding-top: 5%;
    width: 90%;
    height: 33%;
    margin-left: 5%;
    border-bottom-style: solid;
    border-bottom-width: 1.5px;
    border-bottom-color: lightgray;
    text-align: left;
}

.group-chat-attachments {
    padding-top: 5%;
    width: 90%;
    height: 33%;
    margin-left: 5%;
    text-align: left;
}

.select-friend {
  border-style: none;
  background-color: #f9f9fc;
  width: 100%;
  min-height: 60px;

}

.select-friend:focus {
  background: rgb(0,0,0);
  background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(233,205,115,0.99) 1%, rgba(255,247,221,1) 60%, rgba(250,248,236,1) 100%);
}

.friend-display {
  height: 10%;
  display: flex;
}

.friend-avatar {
  border-radius: 50%;
  width: 18%;
  align-self: center;
}

.friend-text-display {
  padding-left: 4%;
  display: flex;
  flex-direction: column;
  padding-top: 6.5%;
  text-align: left;
}

.friend-avatar-name {
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 0%;
}

.friend-latest-message {
  font-size: 10px;
  overflow: hidden;
  white-space: nowrap;
  max-width: 28ch;
  text-overflow: ellipsis;
}

.show-message-container {
  display: none;
}

.show-messages {
  display: none;
}

@media only screen and (max-width: 572px) {
  .chat-room-right {
    display: none;
  }

  .chat-room-side-nav {
    display: none;
  }

  .chat-room-left {
    width: 100%;
    z-index: 1;
    bottom: 0;
    margin-bottom: 60px;
  }

  .chat-room-container {
    width: 100%;
    margin-top: 10%;
  }

  .chat-room-header {
    background-color: rgb(235, 189, 38);
    display: flex;
    justify-content: flex-start;
    height: 5%;
  }

  .chat-room-container form {
    margin-bottom: 5%;
  }

  .logo-section-chat-room {
    display: none;
  }

  .show-message-container {
    display: flex;
    text-align: center;
    height: 75%;
    align-self: center;
    margin-left: 2%;
  }

  .show-messages {
    display: inline-block;
  }

  .search-messages {
    display: none;
  }

  .friend-latest-message {
    max-width: 24ch;
  }

}